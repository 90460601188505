import React, { useState, useEffect } from 'react';
import 'sass/globals.scss';
import './ScanManifestView.scss';
import {
  Container,
  Grid,
  Box,
  Typography,
  Button,
  CircularProgress,
  TextField,
  Card,
  CardContent,
  ButtonGroup,
} from '@mui/material';
import Page from 'sharedComponents/Page';
import { useSelector } from 'react-redux';
import { selectScale } from 'modules/scales/storeSliceScales';
import { selectAuthUser } from 'modules/auth/storeSliceAuth';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { useNavigate } from 'react-router-dom';
import { generateQRcodeBase64 } from 'services/logic/qrCode';
import useShowError from 'modules/errors';
import CompaniesApi from 'services/postgres/CompaniesApi';
import Hider from '../../sharedComponents/Hider';
import { VerifyManifestCurrentPage } from './VerifyManifestView';
import { Manifest } from 'lg-helpers/dist/shared/types/manifests';
import Command from '../../services/firebase/Command';

const companiesApi = new CompaniesApi();
const command = new Command();

interface ScanManifestProps {
  selectedManifest: Manifest;
  onPageChange: (page: VerifyManifestCurrentPage) => void;
}

const ScanManifestView = (props: ScanManifestProps) => {
  const [truckNumber, setTruckNumber] = useState(
    (props.selectedManifest?.signatureDriver?.truckNumber || '').toString()
  );
  const authUser = useSelector(selectAuthUser);
  const scale = useSelector(selectScale);
  const [isLoading, setIsLoading] = useState(true);
  const [scaleCompany, setScaleCompany] = useState<any>();
  const [profileRoot, setProfileRoot] = useState<any>(null);
  const showError = useShowError();
  const [profileAndTypeQRcodeBase64, setProfileAndTypeQRcodeBase64] = useState<string | undefined>(
    undefined
  );
  const [manifestNumberQRcodeBase64, setManifestNumberQRcodeBase64] = useState<string | undefined>(
    undefined
  );
  const [type, setType] = useState('');
  const navigate = useNavigate();

  const profileAndType = `${profileRoot}${type}`;
  const qrPrefix = (scale?.externalApiMappings?.QrCodePrefix || '').trim();
  const manifestNumberQRcodeContent = props.selectedManifest
    ? qrPrefix + props.selectedManifest.number
    : '';
  const isVisibleProfileQrCode = scaleCompany && !scaleCompany.hideProfileQrCode;

  const handleClickVerifyManifest = async () => {
    if (!truckNumber || !truckNumber.trim()) {
      showError({
        title: 'Please, enter truck number!',
        text: "You can't verify manifest without truck number.",
        duration: 5000,
      });
      return;
    }

    await setManifestQrTextAndProfile();
    await setDatabaseTruckNumber();
    props.onPageChange(VerifyManifestCurrentPage.SignManifest);
  };

  const setDatabaseTruckNumber = async () => {
    try {
      await command.manifests.setManifestDriverTruckNumber(props.selectedManifest?.id, truckNumber);
    } catch (e) {
      console.error('error:', e);
      showError({ title: 'An error occurred when setting the driver truck number' });
    }
  };

  const setManifestQrTextAndProfile = async () => {
    try {
      const qrCodeText = `${profileAndType},${manifestNumberQRcodeContent}`;
      await command.manifests.setManifestQrCodeTextAndProfileAndTypeByScaleAttendant(
        props.selectedManifest.id,
        qrCodeText,
        profileAndType
      );
    } catch (err) {
      console.error(err);
      showError({ title: 'Error saving profile' });
    }
  };

  useEffect(() => {
    if (!props.selectedManifest) return;

    command.manifests.setManifestStartedProcessingAtByScaleAttendant(props.selectedManifest.id);
  }, [props.selectedManifest]);

  useEffect(() => {
    if (!props.selectedManifest || profileRoot || !manifestNumberQRcodeContent || isLoading) return;

    const generateManifestQRcodesBase64 = async () => {
      setManifestNumberQRcodeBase64(await generateQRcodeBase64(manifestNumberQRcodeContent));
    };
    generateManifestQRcodesBase64();
    setProfileRoot(props.selectedManifest.signatureScale.profileAndType);
  }, [props.selectedManifest?.id, profileRoot, manifestNumberQRcodeContent, isLoading]);

  useEffect(() => {
    if (
      !type ||
      !scale ||
      !profileRoot ||
      !scale.profileTypes ||
      scale.profileTypes.hidden ||
      !scale.profileTypes.list
    )
      return;

    let initialType = '';
    const matchingProfileTypes = scale.profileTypes.list.filter((profileType: any) =>
      profileRoot.toUpperCase().endsWith(profileType.value.toUpperCase())
    );
    matchingProfileTypes.sort((a: any, b: any) => (a.value.length > b.value.length ? -1 : 1));

    if (matchingProfileTypes.length) {
      initialType = matchingProfileTypes[0].value;
      setProfileRoot(profileRoot.substring(0, profileRoot.length - initialType.length));
    }

    setType(initialType);
  }, [scale, profileRoot]);

  useEffect(() => {
    if (!profileAndType) return;

    const generateProfileAndTypeQRcodeBase64 = async () => {
      setProfileAndTypeQRcodeBase64(await generateQRcodeBase64(profileAndType));
    };
    generateProfileAndTypeQRcodeBase64();
  }, [profileAndType]);

  useEffect(() => {
    if (!props.selectedManifest || !authUser) return;

    setIsLoading(true);
    const load = async () => {
      try {
        const company = await companiesApi.getById(authUser.companiesIds[0]);
        setScaleCompany(company);
      } catch (err) {
        console.error('Failed to load scale details', err);
      } finally {
        setIsLoading(false);
      }
    };
    load();
  }, [props.selectedManifest, authUser]);

  // I'm so sorry for the "any" type.. but LCP is for the children!! :D
  const getFilteredCustomInputs = () => {
    const customInputsDefinitions: any[] =
      props.selectedManifest?.project?.customInputsDefinitions || [];
    const customInputs: { [key: string]: any } = props.selectedManifest?.customInputs || {};

    // Filter definitions that should be shown on the QR code screen
    const filteredDefinitions = customInputsDefinitions.filter(
      (definition: any) => definition?.showOnQRCodeScreen
    );

    // Map through the filtered definitions and find matching custom inputs
    const filteredCustomInputs = filteredDefinitions
      .map((definition: any) => {
        const key = definition.propertyPathInManifestCustomInputs;
        return customInputs[key] ? { key, value: customInputs[key] } : null;
      })
      .filter((input: any): input is { key: any; value: any } => input !== null);

    return filteredCustomInputs;
  };

  const filteredCustomInputs = getFilteredCustomInputs();

  return (
    <Page className="ScanManifestView min-padding-and-height" title="Scan manifest">
      <Container>
        <Box display="flex" justifyContent="space-between" mb={2}>
          <Button
            onClick={() => navigate(-1)}
            variant="outlined"
            startIcon={<NavigateBeforeIcon />}
          >
            Back
          </Button>
          <Button color="primary" variant="contained" onClick={handleClickVerifyManifest}>
            Verify manifest
          </Button>
        </Box>
        {!props.selectedManifest ? (
          <Box display="flex" justifyContent="center" alignItems="center">
            <Typography variant="h3" color="error">
              Select a manifest!
            </Typography>
          </Box>
        ) : isLoading ? (
          <Box display="flex" justifyContent="center" alignItems="center">
            <CircularProgress color="inherit" size={30} />
          </Box>
        ) : (
          <Grid container spacing={2} justifyContent="center">
            {filteredCustomInputs.length > 0 && (
              <Grid item xs={12} mt={2}>
                <Box style={{ marginRight: 10 }} bgcolor="#e0e0e0" p={2}>
                  <Typography color="textSecondary" variant="h6">
                    Custom Inputs:
                  </Typography>
                  {filteredCustomInputs.map(({ key, value }) => (
                    <Typography key={key} color="textPrimary" variant="h5">
                      {key}: {value.value}
                    </Typography>
                  ))}
                </Box>
              </Grid>
            )}
            <Grid container spacing={2} justifyContent="center" style={{ marginTop: 10 }}>
              <Grid item xs={12}>
                <Card variant="outlined">
                  <CardContent style={{ backgroundColor: '#cce6cc' }}>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={3} textAlign="left">
                        <Box border={12} borderColor="green" display="inline-block" p={1}>
                          <img
                            src={profileAndTypeQRcodeBase64}
                            style={{
                              width: '100%',
                              height: 'auto',
                              minWidth: '125px',
                              minHeight: '125px',
                            }}
                            alt="Profile QR code"
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={9} textAlign="center">
                        <Typography variant="h5">Enter Profile Number:</Typography>
                        <Typography variant="h4">{profileRoot}</Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} mt={2}>
                <Card variant="outlined">
                  <CardContent style={{ backgroundColor: '#d3e8f0' }}>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={8} textAlign="center">
                        <Typography variant="h5">Enter Manifest Number:</Typography>
                        <Typography variant="h4">{manifestNumberQRcodeContent}</Typography>
                      </Grid>

                      <Grid item xs={4} textAlign="right">
                        <Box border={12} borderColor="#4463A1" display="inline-block" p={1}>
                          <img
                            src={manifestNumberQRcodeBase64}
                            style={{
                              width: '100%',
                              height: 'auto',
                              minWidth: '125px',
                              minHeight: '125px',
                            }}
                            alt="Manifest QR code"
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>

            <Grid item xs={12} mt={2}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography color="textSecondary" variant="h5">
                    Generator:&nbsp;
                    <span className="ScanManifestView__labels--grey">
                      {props.selectedManifest.project?.generatorCompany?.name}
                    </span>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography color="textSecondary" variant="h5">
                    Bill to:&nbsp;
                    <span className="ScanManifestView__labels--grey">
                      {props.selectedManifest.project?.billTo}
                    </span>
                  </Typography>
                </Grid>
                {props.selectedManifest.signatureDriver?.secondaryTruckingCompany && (
                  <Grid item xs={12}>
                    <Typography color="textSecondary" variant="h5">
                      Carrier:&nbsp;
                      <span className="ScanManifestView__labels--grey">
                        {props.selectedManifest.signatureDriver.secondaryTruckingCompany}
                      </span>
                    </Typography>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Typography color="textSecondary" variant="h5">
                    Transporter:&nbsp;
                    <span className="ScanManifestView__labels--grey">
                      {props.selectedManifest.signatureDriver.truckingCompany}
                    </span>
                  </Typography>
                </Grid>
                <Grid item xs={12} container alignItems="center">
                  <Grid item xs={12} sm={6}>
                    <Typography color="textSecondary" variant="h5">
                      Truck#&nbsp;
                    </Typography>
                    <TextField
                      variant="outlined"
                      fullWidth
                      className="ScanManifestView__input--truck-number"
                      onChange={event => setTruckNumber(event.target.value)}
                      defaultValue={props.selectedManifest?.signatureDriver?.truckNumber}
                    />
                  </Grid>
                  {props.selectedManifest.weight && (
                    <Grid item xs={12} sm={6}>
                      <Typography color="textSecondary" variant="h5">
                        Manifest Weight&nbsp;
                      </Typography>
                      <TextField value={props.selectedManifest.weight} disabled fullWidth />
                    </Grid>
                  )}
                </Grid>
                <Hider
                  showContent={
                    scale?.profileTypes?.list &&
                    scale.profileTypes.list.length > 0 &&
                    scale.profileTypes.list.some(
                      (profileType: any) => profileType.display.trim() !== ''
                    )
                  }
                >
                  <Box display="flex" flexWrap="wrap" mt={2}>
                    <Typography
                      style={{ paddingRight: 10, paddingLeft: 15 }}
                      color="textSecondary"
                      variant="h5"
                    >
                      Type
                    </Typography>
                    <ButtonGroup
                      size="large"
                      orientation="horizontal"
                      aria-label="horizontal outlined primary button group"
                    >
                      {(scale?.profileTypes?.list || []).map((profileType: any) => (
                        <Button
                          className="ScanManifestView__type--btn"
                          key={profileType.id}
                          onClick={() => setType(profileType)}
                          variant={type === profileType ? 'contained' : 'outlined'}
                        >
                          <Typography
                            color="textPrimary"
                            variant={type === profileType ? 'h5' : 'h6'}
                          >
                            {profileType.display}{' '}
                            {/* Use the 'display' property or whichever string property you want to display */}
                          </Typography>
                        </Button>
                      ))}
                    </ButtonGroup>
                  </Box>
                </Hider>
              </Grid>
            </Grid>
            <Grid item xs={12} mt={2}>
              <Box display="flex" justifyContent="center" flexWrap="wrap">
                <Button
                  color="primary"
                  variant="contained"
                  onClick={handleClickVerifyManifest}
                  fullWidth
                  style={{ padding: '16px' }}
                >
                  Verify manifest
                </Button>
              </Box>
            </Grid>
          </Grid>
        )}
      </Container>
    </Page>
  );
};

export default ScanManifestView;
